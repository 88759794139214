import React from 'react';

import MusicRow from './MusicRow';
import { useGetAlbumDetailsQuery } from '../../features/albums/albumsAPI';

interface TopAlbumRowProp {
  albumId: string;
  count: number;
}
const TopAlbumRow = (album: TopAlbumRowProp) => {
  const { data: albumDetails } = useGetAlbumDetailsQuery(album.albumId);
  return (
    <li
      className='p-2 flex justify-between items-center hover:bg-spotify rounded-md'
      key={album.albumId}
    >
      <MusicRow
        imageSrc={albumDetails?.imageUrl}
        title={albumDetails?.name}
        subtitle={albumDetails?.artists.map((artist) => artist.name).join(', ')}
      />
      <div className='ml-2'>{album.count}</div>
    </li>
  );
};

export default TopAlbumRow;
