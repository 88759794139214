import React from 'react';

import FriendsList from './FriendsList';

export interface FriendsProp {
  className?: string;
  title: string;
  subtitle?: string;
  friends: string[];
}

const Friends = (prop: FriendsProp) => {
  return (
    <div className={prop.className}>
      <h2 className='text-3xl'>{prop.title}</h2>
      {prop.subtitle && <div className='mt-1'>{prop.subtitle}</div>}
      <div className='mt-4'>
        <FriendsList friends={prop.friends} />
      </div>
    </div>
  );
};

export default Friends;
