import React from 'react';

import MusicRow from './MusicRow';
import { useGetArtistDetailsShortQuery } from '../../features/artists/artistAPI';

interface TopArtistRowProp {
  artistId: string;
  count: number;
}

const TopArtistsRow = (artist: TopArtistRowProp) => {
  const { data: artistDetails } = useGetArtistDetailsShortQuery({
    id: artist.artistId,
  });
  return (
    <li className='p-2 flex justify-between items-center hover:bg-spotify rounded-md'>
      <MusicRow
        disableSubtitle={true}
        imageSrc={artistDetails?.imageUrl}
        title={artistDetails?.name}
      />
      <div className='ml-2'>{artist.count}</div>
    </li>
  );
};

export default TopArtistsRow;
