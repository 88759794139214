import React from 'react';
import { Link } from 'react-router-dom';

import TopArtistsRow from './TopArtistsRow';
import { ArtistCount } from '../../features/artists/artistAPI';

interface TopArtistsProps {
  artists: ArtistCount[];
}

const TopArtists = ({ artists }: TopArtistsProps) => {
  return (
    <div>
      <div className='text-3xl mb-4'>Top 10 Artists</div>
      <ul>
        {artists.map((artist) => {
          return (
            <Link key={artist.id} to={`/artist/${artist.id}`}>
              <TopArtistsRow artistId={artist.id} count={artist.count} />
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

export default TopArtists;
