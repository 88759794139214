import React from 'react';

import { useAuth } from '../helpers/AuthProvider';

const Login = () => {
  const { loginUrl } = useAuth();

  return (
    <div className="bg-[url('./assets/images/background.jpg')] bg-cover absolute top-0 left-0 right-0 bottom-0 bg-center">
      <div className='mt-24 text-white flex justify-center'>
        <div className='flex flex-col'>
          <div className='text-6xl md:text-8xl font-lobster'>
            Spotify Tracker
          </div>
          <div className='flex justify-center mt-12'>
            <a className='' href={loginUrl()}>
              <div className='bg-spotify px-8 py-5 rounded-full text-2xl flex items-center hover:bg-spotify-light'>
                <img
                  className='h-8'
                  src='images/spotify/Spotify_Icon_RGB_White.png'
                  alt='Spotify logo'
                />
                <div className='ml-3'>Login with Spotify</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
