import React from 'react';

import { selectDisplayUser, setDisplayUser } from './filterSlice';
import DisplayName from '../friends/DisplayName';
import { useGetFriendsQuery } from '../friends/friendAPI';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useAuth } from '../../helpers/AuthProvider';

const UserPicker = () => {
  const displayUser = useAppSelector(selectDisplayUser);
  const { user } = useAuth();
  const { data: friends } = useGetFriendsQuery();

  const dispatch = useAppDispatch();
  if (displayUser === '') {
    dispatch(setDisplayUser(user.id));
  }

  return (
    <select
      value={displayUser}
      onChange={(event) => dispatch(setDisplayUser(event.target.value))}
      className='block mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
    >
      <option value={user.id}>
        <DisplayName userId={user.id} />
      </option>
      {friends
        ?.filter((friend) => friend.friendId === user.id)
        .map((friend) => friend.userId)
        .map((user) => {
          return (
            <option key={user} value={user}>
              <DisplayName userId={user} />
            </option>
          );
        })}
    </select>
  );
};

export default UserPicker;
