import React, { useState } from 'react';

import { useAppSelector } from '../app/hooks';
import DateRangePicker from '../components/dashboard/DateRangePicker';
import TrackAndArtist from '../components/dashboard/TrackAndArtist';
import Card from '../components/layout/Card';
import { selectDisplayUser } from '../features/filter/filterSlice';
import UserPicker from '../features/filter/UserPicker';
import { useGetPlaysHistoryQuery } from '../features/plays/playAPI';

const History = () => {
  const [dateRange, setDateRange] = useState(999);
  const displayUser = useAppSelector(selectDisplayUser);

  const { data: history } = useGetPlaysHistoryQuery({
    user: displayUser,
    count: 10,
    skip: 0,
  });

  const historyGrouped =
    history?.reduce((r, a) => {
      r[a.timeOfPlay.split('T')[0]] = r[a.timeOfPlay.split('T')[0]] || [];
      r[a.timeOfPlay.split('T')[0]].push(a);
      return r;
    }, Object.create(null)) || {};

  return (
    <div>
      <div className='sm:flex justify-between'>
        <h1 className='text-5xl'>History</h1>
        <div className='flex mt-3 sm:mt-0'>
          <div className='mr-2 '>
            <UserPicker />
          </div>
          <div>
            <DateRangePicker
              disabled={true}
              value={dateRange}
              onChange={(event) => setDateRange(event)}
            />
          </div>
        </div>
      </div>
      <Card className='mt-4'>
        {Object.keys(historyGrouped).map((key) => {
          return (
            <div className='mb-3' key={key}>
              <div className='flex justify-center mb-3'>
                <div className='px-4 text-white rounded-full bg-spotify'>
                  {key}
                </div>
              </div>
              {historyGrouped[key].map((play: any) => {
                return (
                  <div
                    key={play.trackId + play.timeOfPlay}
                    className='flex justify-between mb-2 items-center'
                  >
                    <TrackAndArtist trackId={play.trackId} />
                    <div className='tabular-nums'>
                      {new Date(play.timeOfPlay).toLocaleTimeString()}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </Card>
    </div>
  );
};
export default History;
