import React, { useEffect, useState } from 'react';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { PlaysByDay } from '../../features/plays/playAPI';

export interface ChartData {
  xAxis: number;
  Plays: number;
}

function PlaysByDayChart({
  data,
  dateRange,
}: {
  data: PlaysByDay[];
  dateRange: number;
}) {
  const [filteredPlaysByDay, setFilteredPlaysByDay] = useState<ChartData[]>([]);

  useEffect(() => {
    if (data) dateFilter(data, dateRange);
  }, [dateRange, data]);

  const dateFilter = (array: PlaysByDay[], dateRange: number) => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - dateRange);
    const endDate = new Date();

    const filtered = array.filter(
      (playsByDay) =>
        new Date(playsByDay.day) > startDate &&
        new Date(playsByDay.day) < endDate
    );

    setFilteredPlaysByDay(fillInMissingDates(filtered, startDate, endDate));
  };

  const fillInMissingDates = (
    array: PlaysByDay[],
    startDate: Date,
    endDate: Date
  ): ChartData[] => {
    const arrayCopy = [...array];
    const res = [] as ChartData[];
    for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
      const index = arrayCopy.findIndex(
        (element: PlaysByDay) =>
          element.day.split('T')[0] === i.toISOString().split('T')[0]
      );
      res.push({
        xAxis: i.getTime(),
        Plays: index > -1 ? arrayCopy[index].value : 0,
      });
      if (index > -1) arrayCopy.splice(index, 1);
    }
    return res;
  };

  return (
    <ResponsiveContainer width='99%'>
      <LineChart
        width={100}
        height={100}
        data={filteredPlaysByDay}
        margin={{
          top: 10,
          right: 32,
          left: 10,
        }}
      >
        <Line
          type='monotone'
          dataKey='Plays'
          stroke='#1DB954'
          width={10}
          strokeWidth={3}
          dot={false}
        />
        <Tooltip content={<CustomTooltip />} />
        <XAxis
          dataKey='xAxis'
          scale='time'
          type='number'
          domain={['dataMin', 'dataMax']}
          tickFormatter={dateFormatter}
          interval='preserveStartEnd'
          tickCount={20}
          minTickGap={1}
          style={{
            fontSize: '0.6rem',
          }}
        />
        <YAxis
          tickCount={5}
          allowDecimals={false}
          width={30}
          interval='preserveStartEnd'
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

const dateFormatter = (date: number) => {
  return new Date(date).toISOString().split('T')[0];
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    payload: ChartData;
  }[];
}

const CustomTooltip = (props: CustomTooltipProps) => {
  const { active, payload } = props;
  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;
    return (
      <div className='bg-gray-100 p-2 rounded-md'>
        <div className='text-sm text-gray-600'>
          {currData
            ? new Date(currData.xAxis).toISOString().split('T')[0]
            : ' -- '}
        </div>
        <div className='text-lg'>
          {'Plays : '}
          {currData ? currData.Plays : ' -- '}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default PlaysByDayChart;
