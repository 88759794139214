import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import { Bars3Icon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { SideBarProps } from './Sidebar';
import { useAuth } from '../../helpers/AuthProvider';

const DesktopSideBar = ({ routes }: SideBarProps) => {
  const { logout } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const NavLinkClasses = `inline-block items-center flex w-full rounded-md hover:bg-gray-200 mb-3 
  ${collapsed ? 'justify-center py-2' : 'px-4 py-2'}`;

  return (
    <div
      className={`p-5 flex min-h-screen h-full flex-col bg-gray-100 ${
        collapsed ? 'w-20' : 'w-72'
      }`}
    >
      <div className='flex mb-12 items-center justify-between'>
        <div
          className={`font-lobster text-3xl text-spotify mr-2 
          ${collapsed ? 'hidden' : 'visible'}`}
        >
          Spotify Tracker
        </div>
        <button onClick={() => setCollapsed(!collapsed)} className='h-8 w-8'>
          <Bars3Icon />
        </button>
      </div>
      <ul>
        {routes.map((route) => {
          return (
            <li key={route.link}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? `${NavLinkClasses} bg-gray-200` : NavLinkClasses
                }
                to={route.link}
              >
                <div className={`h-5 w-5 ${collapsed ? '' : 'mr-2'}`}>
                  {route.icon}
                </div>
                <div className={`${collapsed ? 'hidden' : 'visible'}`}>
                  {route.label}
                </div>
              </NavLink>
            </li>
          );
        })}
      </ul>
      <div className='flex-1 flex flex-col'>
        <div className='flex-1'></div>
        <button
          onClick={logout}
          className={`flex items-center text-left rounded-md hover:bg-gray-200 ${
            collapsed ? 'justify-center py-2' : 'px-4 py-2'
          }`}
        >
          <ArrowLeftOnRectangleIcon
            className={`h-5 w-5 ${collapsed ? '' : 'mr-2'}`}
          />
          <div className={`${collapsed ? 'hidden' : 'visible'}`}>Logout</div>
        </button>
      </div>
    </div>
  );
};

export default DesktopSideBar;
