import React from 'react';
import Skeleton from 'react-loading-skeleton';

const MusicRow = (props: {
  disablePicture?: boolean;
  imageSrc?: string;
  title?: string;
  subtitle?: string;
  disableSubtitle?: boolean;
}) => {
  return (
    <div className='flex items-center'>
      {!props.disablePicture && (
        <div className='mr-2 w-12 flex-none'>
          {!props.imageSrc && <Skeleton className='h-12' />}
          {props.imageSrc && (
            <img
              className='h-12 mx-auto rounded-sm'
              src={props.imageSrc}
              alt={`Image for ${props.title}`}
            />
          )}
        </div>
      )}
      <div>
        <div>{props.title || <Skeleton width={200} />}</div>
        {!props.disableSubtitle && (
          <div className='text-gray-500 text-sm'>
            {props.subtitle || <Skeleton width={200} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default MusicRow;
