const local = {
  apiUrl: 'https://localhost:5001',
};

const dev = {
  apiUrl: 'https://spotitrack-backend-dev.azurewebsites.net',
};

const prod = {
  apiUrl: '/api',
};
let config;
switch (process.env.REACT_APP_STAGE) {
  case 'prod':
    config = prod;
    break;
  case 'dev':
    config = dev;
    break;
  case 'local':
    config = local;
    break;
  default:
    config = local;
    break;
}

export default {
  // Add common config values here
  ...config,
};
