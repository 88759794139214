import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { store } from './app/store';
import AppLayout from './components/layout/AppLayout';
import ErrorPage from './error-page';
import { AuthProvider } from './helpers/AuthProvider';
import ProtectedRoute from './helpers/ProtectedRoute';
import Account from './pages/Account';
import Album from './pages/Album';
import Artist from './pages/Artist';
import Authentication from './pages/Authentication';
import Dashboard from './pages/Dashboard';
import History from './pages/History';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
      {
        path: '/authenticate',
        element: <Authentication />,
      },
      {
        element: (
          <ProtectedRoute>
            <AppLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: '/dashboard',
            element: <Dashboard />,
          },
          {
            path: '/account',
            element: <Account />,
          },
          {
            path: '/artist/:artistId',
            element: <Artist />,
          },
          {
            path: '/album/:albumId',
            element: <Album />,
          },
          {
            path: '/history',
            element: <History />,
          },
        ],
      },
    ],
  },
  {
    element: <NotFound />,
    path: '*',
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
