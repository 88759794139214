import React, { useState } from 'react';

import { FriendsProp } from './Friends';
import FriendsList from './FriendsList';
import {
  useAddFriendMutation,
  useDeleteFriendMutation,
} from '../../features/friends/friendAPI';

interface FriendsWithSearchProp extends FriendsProp {
  placeholder?: string;
}

const FriendsWithSearch = (prop: FriendsWithSearchProp) => {
  const [addFriend] = useAddFriendMutation();
  const [searchBox, setSearchBox] = useState('');
  const [deleteFriend] = useDeleteFriendMutation();

  return (
    <div className={prop.className}>
      <h2 className='text-3xl'>{prop.title}</h2>
      {prop.subtitle && <div className='mt-1'>{prop.subtitle}</div>}
      <div className='flex mt-4'>
        <input
          type='text'
          className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
          placeholder={prop.placeholder}
          onChange={(event) => setSearchBox(event.target.value)}
        />
        <button
          onClick={() => addFriend(searchBox)}
          className='bg-spotify hover:bg-spotify-light rounded-md w-24 ml-3 text-white'
        >
          Add
        </button>
      </div>
      <div className='mt-2'>
        <FriendsList friends={prop.friends} onDelete={deleteFriend} />
      </div>
    </div>
  );
};

export default FriendsWithSearch;
