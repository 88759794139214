import React from 'react';

import TrackListRow from './TrackListRow';
import { useAppSelector } from '../../app/hooks';
import { selectDisplayUser } from '../../features/filter/filterSlice';
import { useGetTrackPlaysQuery } from '../../features/plays/playAPI';

interface TrackListProps {
  tracks: {
    id: string;
    name: string;
  }[];
}

const TrackList = (props: TrackListProps) => {
  const displayUser = useAppSelector(selectDisplayUser);

  const { data: trackPlays } = useGetTrackPlaysQuery({
    user: displayUser,
    id: props.tracks.map((track) => track.id),
  });

  const calculatePercentage = (plays: number, highestPlays: number) => {
    let value = 0;
    if (highestPlays !== 0) {
      value = (plays / highestPlays) * 100;
    }

    return value;
  };

  const highestPlay = Math.max(
    ...(trackPlays?.map((play) => play.count) || [0])
  );

  return (
    <div>
      {props.tracks.map((track, index) => {
        const plays =
          trackPlays?.find((play) => play.id === track.id)?.count || 0;

        return (
          <TrackListRow
            trackNumber={index + 1}
            key={track.id}
            trackName={track.name}
            trackId={track.id}
            count={plays}
            className='mb-1'
            percentageBackground={calculatePercentage(plays, highestPlay)}
          />
        );
      })}
    </div>
  );
};

export default TrackList;
