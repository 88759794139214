import React from 'react';

import Friends from '../components/account/Friends';
import FriendsWithSearch from '../components/account/FriendsWithSearch';
import Card from '../components/layout/Card';
import DisplayName from '../features/friends/DisplayName';
import { useGetFriendsQuery } from '../features/friends/friendAPI';
import { useAuth } from '../helpers/AuthProvider';

const Account = () => {
  const { data } = useGetFriendsQuery();
  const { user } = useAuth();

  return (
    <div>
      <h1 className='text-5xl'>Account</h1>
      <Card className='mt-8'>
        <h2 className='text-3xl'>Spotify Details</h2>
        <div className='flex'>
          <div className='mt-4 flex-1'>
            <div className='text-gray-500'>Spotify ID</div>
            <div className='text-xl'>{user.id}</div>
          </div>
          <div className='mt-4 flex-1'>
            <div className='text-gray-500'>Display name</div>
            <span className='text-xl'>
              <DisplayName userId={user.id} />
            </span>
          </div>
        </div>
      </Card>
      <Card className='mt-8'>
        <FriendsWithSearch
          title='My friends'
          placeholder='Spotify ID'
          subtitle='Friends you add here will be able to view your profile and statistics'
          friends={
            data
              ?.filter((friend) => friend.userId === user.id)
              .map((friend) => friend.friendId) || []
          }
        />
      </Card>
      <Card className='mt-8'>
        <Friends
          title='My followers'
          subtitle='People here have given you permission to view their profile'
          friends={
            data
              ?.filter((friend) => friend.friendId === user.id)
              .map((friend) => friend.userId) || []
          }
        />
      </Card>
    </div>
  );
};
export default Account;
