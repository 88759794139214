import React from 'react';
import { useRouteError } from 'react-router-dom';

interface UseRouteErrorInterface {
  statusText: string;
  message: string;
}

const ErrorPage = () => {
  const error = useRouteError() as UseRouteErrorInterface;
  // eslint-disable-next-line no-console
  console.error(error);

  return (
    <div className='text-center mt-12 text-3xl'>
      <h1 className='text-5xl mb-5'>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};

export default ErrorPage;
