import React, { createContext, useContext, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import config from './config';
import { useLocalStorage } from './UseLocalStorage';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const AuthContext = createContext<AuthProviderInterface>(undefined);

interface User {
  token: string;
  displayName: string;
  id: string;
}

interface AuthProviderInterface {
  user: User;
  loginUrl: () => string;
  logout: () => void;
  authenticate: (code: string) => void;
}

export const AuthProvider = () => {
  const [user, setUser] = useLocalStorage('user', null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const loginUrl = (): string => {
    return config.apiUrl + '/user/login';
  };

  // call this function to sign out logged-in user
  const logout = () => {
    setUser(null);
    navigate('/', { replace: true });
  };

  const authenticate = async (code: string) => {
    const requestOptions = {
      method: 'GET',
    };
    return fetch(
      `${config.apiUrl}/user/authenticate?code=${code}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((user: User) => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          setUser(user);
          navigate('/dashboard');
        }

        return user;
      });
  };

  const value = useMemo(
    () => ({
      user,
      loginUrl,
      logout,
      authenticate,
    }),
    [user]
  );
  return (
    <AuthContext.Provider value={value}>
      <Outlet />
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthProviderInterface => {
  return useContext(AuthContext);
};
