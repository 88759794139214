import { TrashIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';

import DisplayName from '../../features/friends/DisplayName';

interface FriendsListRowProps {
  friendId: string;
  onDelete?: (friendId: string) => void;
}

const FriendsListRow = (props: FriendsListRowProps) => {
  const [hover, setHover] = useState(false);

  return (
    <li
      key={props.friendId}
      className={`px-2 py-2 mb-2 flex justify-between rounded-md ${
        hover ? 'bg-gray-200' : ''
      }`}
    >
      <div>
        <span className='text-xl'>
          <DisplayName userId={props.friendId} />
        </span>
        <div className='text-gray-500'>{props.friendId}</div>
      </div>
      {props.onDelete !== undefined && (
        <button
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => props.onDelete?.(props.friendId)}
        >
          <TrashIcon className='h-6 w-6 text-red-700' />
        </button>
      )}
    </li>
  );
};

export default FriendsListRow;
