import React from 'react';

interface TrackListRowProps {
  trackNumber: number;
  trackId: string;
  trackName: string;

  percentageBackground: number;
  count: number;
  className: string;
}

const TrackListRow = (props: TrackListRowProps) => {
  return (
    <div
      className={`flex justify-between px-4 py-2 rounded-md ${props.className}`}
      style={{
        backgroundImage: `linear-gradient(90deg, #bbe2c9 ${props.percentageBackground}%, #f6f6f6 0%)`,
      }}
    >
      <div>
        {props.trackNumber}. {props.trackName}
      </div>
      <div>{props.count}</div>
    </div>
  );
};

export default TrackListRow;
