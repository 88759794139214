import React, { useState } from 'react';

import { useAppSelector } from '../app/hooks';
import DateRangePicker from '../components/dashboard/DateRangePicker';
import PlaysByDayChart from '../components/dashboard/PlaysByDayChart';
import TopAlbums from '../components/dashboard/TopAlbums';
import TopArtists from '../components/dashboard/TopArtists';
import TopSongs from '../components/dashboard/TopSongs';
import Card from '../components/layout/Card';
import { useGetTopAlbumsQuery } from '../features/albums/albumsAPI';
import {
  useGetArtistsCountQuery,
  useGetTopArtistsQuery,
} from '../features/artists/artistAPI';
import { selectDisplayUser } from '../features/filter/filterSlice';
import UserPicker from '../features/filter/UserPicker';
import {
  useGetPlaysByDayQuery,
  useGetTopTracksQuery,
  useGetTotalCountQuery,
} from '../features/plays/playAPI';

const Dashboard = () => {
  const [dateRange, setDateRange] = useState(31);
  const displayUser = useAppSelector(selectDisplayUser);

  const { data: totalPlays } = useGetTotalCountQuery({
    days: dateRange,
    user: displayUser,
  });
  const { data: artists } = useGetArtistsCountQuery({
    days: dateRange,
    user: displayUser,
  });
  const { data: topTracks } = useGetTopTracksQuery({
    days: dateRange,
    user: displayUser,
  });
  const { data: topAlbums } = useGetTopAlbumsQuery({
    days: dateRange,
    user: displayUser,
  });
  const { data: topArtists } = useGetTopArtistsQuery({
    days: dateRange,
    user: displayUser,
  });
  const { data: playsByDay } = useGetPlaysByDayQuery(displayUser);

  return (
    <div>
      <div className='sm:flex justify-between'>
        <h1 className='text-5xl'>Dashboard</h1>
        <div className='flex mt-3 sm:mt-0'>
          <div className='mr-2 '>
            <UserPicker />
          </div>
          <div>
            <DateRangePicker
              value={dateRange}
              onChange={(event) => setDateRange(event)}
            />
          </div>
        </div>
      </div>
      <div className='flex mt-8'>
        <Card className='mr-4 w-40'>
          <div className='text-3xl'>{totalPlays}</div>
          <div>Plays</div>
        </Card>
        <Card className='mr-4 w-40'>
          <div className='text-3xl'>{artists?.length}</div>
          <div>Artists</div>
        </Card>
      </div>
      <div className='flex mt-8'>
        <Card className='flex-1 h-96 p-3 pr-0'>
          <PlaysByDayChart data={playsByDay || []} dateRange={dateRange} />
        </Card>
      </div>
      <div className='md:flex mt-8'>
        <Card className='flex-1 mr-0 md:mr-1'>
          <TopSongs tracks={topTracks || []} />
        </Card>
        <Card className='flex-1 ml-0 mr-0 md:mr-1 md:ml-1'>
          <TopAlbums albums={topAlbums || []} />
        </Card>
        <Card className='flex-1 ml-0 md:ml-1'>
          <TopArtists artists={topArtists || []} />
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
