import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { SideBarProps } from './Sidebar';

const MobileSideBar = ({ routes }: SideBarProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const NavLinkClasses = `inline-block items-center flex w-full rounded-md hover:bg-gray-200 mb-3 
  ${collapsed ? 'justify-center py-2' : 'px-4 py-2'}`;

  return (
    <div className='mt-2 ml-4 bg-gray-100"'>
      <button onClick={() => setCollapsed(false)} className='h-8 w-8'>
        <Bars3Icon />
      </button>
      <div
        className={`absolute top-0 bottom-0 right-0 left-0 bg-gray-100 h-screen ${
          collapsed ? 'hidden' : 'block'
        }`}
      >
        <button
          onClick={() => setCollapsed(true)}
          className='mt-2 ml-4 h-8 w-8'
        >
          <XMarkIcon />
        </button>
        <ul className='p-3'>
          {routes.map((route) => {
            return (
              <li key={route.link}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? `${NavLinkClasses} bg-gray-200` : NavLinkClasses
                  }
                  to={route.link}
                  onClick={() => setCollapsed(true)}
                >
                  <div className={`h-5 w-5 ${collapsed ? '' : 'mr-2'}`}>
                    {route.icon}
                  </div>
                  <div className={`${collapsed ? 'hidden' : 'visible'}`}>
                    {route.label}
                  </div>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MobileSideBar;
