import {
  ClockIcon,
  Squares2X2Icon,
  UserIcon,
} from '@heroicons/react/24/outline';
import React from 'react';

import DesktopSideBar from './DesktopSideBar';
import MobileSideBar from './MobileSideBar';

export interface SideBarProps {
  routes: Route[];
}

export interface Route {
  label: string;
  link: string;
  disabled?: boolean;
  icon?: JSX.Element;
}

const Sidebar = () => {
  const routes: Route[] = [
    { label: 'Dashboard', link: '/dashboard', icon: <Squares2X2Icon /> },
    { label: 'Recently Played', link: '/history', icon: <ClockIcon /> },
    { label: 'Account', link: '/account', icon: <UserIcon /> },
  ];

  return (
    <div className='h-full h-full sticky top-0 z-10'>
      <div className='hidden md:flex '>
        <DesktopSideBar routes={routes} />
      </div>
      <div className='flex md:hidden z-10 bg-gray-100'>
        <MobileSideBar routes={routes} />
      </div>
    </div>
  );
};

export default Sidebar;
