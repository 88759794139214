import React from 'react';

import { useGetDisplayNameQuery } from './friendAPI';

const DisplayName = ({ userId }: { userId: string }) => {
  const { data } = useGetDisplayNameQuery(userId);

  return <>{data}</>;
};

export default DisplayName;
