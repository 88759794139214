import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from './AuthProvider';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return (
      <>
        <Navigate to='/' />
      </>
    );
  }
  return <>{children}</>;
};

export default ProtectedRoute;
