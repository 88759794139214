import React from 'react';

interface CardProps {
  className?: string;
  children: React.ReactNode;
}

const Card = (props: CardProps) => {
  return (
    <div
      className={
        'p-6 bg-white rounded-lg border border-gray-200 shadow-md ' +
        props.className
      }
    >
      {props.children}
    </div>
  );
};

export default Card;
