import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import TrackList from '../components/album/TrackList';
import DateRangePicker from '../components/dashboard/DateRangePicker';
import { useGetAlbumDetailsQuery } from '../features/albums/albumsAPI';
import UserPicker from '../features/filter/UserPicker';

const Album = () => {
  const { albumId } = useParams();
  if (!albumId) {
    return <div>Loading</div>;
  }
  const [dateRange, setDateRange] = useState(999);

  const { data: albumDetail } = useGetAlbumDetailsQuery(albumId);

  return (
    <div>
      <div className='sm:flex justify-between'>
        <div>
          <h1 className='text-5xl'>
            {albumDetail?.name}
            <span className='ml-2 text-base text-gray-500'>
              ({albumDetail?.releaseDate})
            </span>
          </h1>
          <div className='text-gray-500 mt-2'>
            <Link to={`/artist/${albumDetail?.artists[0].id}`}>
              {albumDetail?.artists[0].name}
            </Link>
          </div>
        </div>

        <div className='flex mt-3 sm:mt-0'>
          <div className='mr-2 '>
            <UserPicker />
          </div>
          <div>
            <DateRangePicker
              disabled={true}
              value={dateRange}
              onChange={(event) => setDateRange(event)}
            />
          </div>
        </div>
      </div>
      <div className='sm:flex mt-4'>
        <div className='sm:w-1/3 sm:mr-4'>
          <img
            src={albumDetail?.imageUrl}
            alt='Album art'
            className='rounded-md mb-4'
          />
        </div>
        <div className='flex-1'>
          <TrackList tracks={albumDetail?.tracks || []} />
        </div>
      </div>
    </div>
  );
};

export default Album;
