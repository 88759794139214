import React from 'react';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';

const AppLayout = () => {
  return (
    <div className='md:flex'>
      <Sidebar />
      <div className='md:mt-12 md:ml-12 mx-4 mt-4 mb-12 flex-1'>
        <Outlet />
      </div>
    </div>
  );
};

export default AppLayout;
