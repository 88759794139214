import React from 'react';

import MusicRow from './MusicRow';
import { useGetTrackDetailsQuery } from '../../features/tracks/trackAPI';

const TrackAndArtist = (props: {
  disablePicture?: boolean;
  trackId: string;
  disableArtist?: boolean;
}) => {
  const { data: trackDetails } = useGetTrackDetailsQuery(props.trackId);
  return (
    <MusicRow
      disablePicture={props.disablePicture}
      disableSubtitle={props.disableArtist}
      imageSrc={trackDetails?.imageUrl}
      title={trackDetails?.name}
      subtitle={trackDetails?.artists.map((artist) => artist.name).join(', ')}
    />
  );
};

export default TrackAndArtist;
