import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export interface FilterState {
  displayUser: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: FilterState = {
  displayUser: '',
  status: 'idle',
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setDisplayUser: (state, action: PayloadAction<string>) => {
      state.displayUser = action.payload;
    },
  },
});

export const { setDisplayUser } = filterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDisplayUser = (state: RootState) => state.filter.displayUser;

export default filterSlice.reducer;
