import React from 'react';
import { Link } from 'react-router-dom';

import TopAlbumRow from './TopAlbumRow';
import { AlbumCount } from '../../features/albums/albumsAPI';

interface TopAlbumsProps {
  albums: AlbumCount[];
}
const TopAlbums = ({ albums }: TopAlbumsProps) => {
  return (
    <div>
      <div className='text-3xl mb-4'>Top 10 Albums</div>
      <ul>
        {albums.map((album) => {
          return (
            <Link key={album.id} to={`/album/${album.id}`}>
              <TopAlbumRow albumId={album.id} count={album.count} />
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

export default TopAlbums;
