import React from 'react';

const DateRangePicker = (props: {
  value: number;
  onChange: (event: number) => void;
  disabled?: boolean;
}) => {
  return (
    <select
      value={props.value}
      disabled={props.disabled}
      onChange={(event) =>
        props.onChange(event.target.value as unknown as number)
      }
      className='disabled:opacity-50 block mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
    >
      <option value={10}>10 days</option>
      <option value={31}>31 days</option>
      <option value={60}>60 days</option>
      <option value={120}>120 days</option>
      <option value={365}>365 days</option>
      <option value={999}>All time</option>
    </select>
  );
};

export default DateRangePicker;
