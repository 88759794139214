import React from 'react';

import TrackAndArtist from './TrackAndArtist';

interface TopSongRowProp {
  trackId: string;
  count: number;
  disablePicture?: boolean;
  disableArtist?: boolean;
}

const TopSongRow = (track: TopSongRowProp) => {
  return (
    <li
      className='p-2 flex justify-between items-center rounded-md'
      key={track.trackId}
    >
      <TrackAndArtist
        disablePicture={track.disablePicture}
        trackId={track.trackId}
        disableArtist={track.disableArtist}
      />
      <div className='ml-2'>{track.count}</div>
    </li>
  );
};

export default TopSongRow;
