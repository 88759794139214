import React from 'react';

const NotFound = () => {
  return (
    <div className='absolute top-0 bottom-0 right-0 left-0 bg-gray-800 text-white'>
      <div className='flex justify-center h-full items-center'>
        <div className='text-red-500 text-9xl'>404</div>
        <div className='flex flex-col text-2xl ml-5'>
          <div className='text-4xl mb-2'>Ooops! Page not found.</div>
          <div>The page you were looking for was not found</div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
