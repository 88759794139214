import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { useGetAlbumDetailsQuery } from '../../features/albums/albumsAPI';

interface AlbumCoverProps {
  albumId: string;
  count: number;
  className?: string;
}

const AlbumCover = (album: AlbumCoverProps) => {
  const { data: albumDetail } = useGetAlbumDetailsQuery(album.albumId);
  return (
    <div className={`${album.className} w-40 p-2 rounded-md`}>
      <div className='mb-2'>
        {!albumDetail?.imageUrl && (
          <div className='w-full h-40'>
            <Skeleton height='100%' />
          </div>
        )}
        {albumDetail?.imageUrl && (
          <img
            className='w-full'
            src={albumDetail?.imageUrl}
            alt={`Album art for ${albumDetail?.name}`}
          />
        )}
      </div>

      <div>
        {albumDetail?.name || <Skeleton width={50} inline={true} />}
        <span className='ml-1 text-sm text-gray-600'>
          (
          {albumDetail?.releaseDate.split('-')[0] || (
            <Skeleton width={50} inline={true} />
          )}
          )
        </span>
      </div>

      <div className='text-sm'>{album.count} plays</div>
    </div>
  );
};

export default AlbumCover;
