import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';

import { useAppSelector } from '../app/hooks';
import AlbumCover from '../components/artist/AlbumCover';
import DateRangePicker from '../components/dashboard/DateRangePicker';
import TopSongRow from '../components/dashboard/TopSongRow';
import Card from '../components/layout/Card';
import {
  useGetArtistDetailsFullQuery,
  useGetArtistDetailsShortQuery,
} from '../features/artists/artistAPI';
import { selectDisplayUser } from '../features/filter/filterSlice';
import UserPicker from '../features/filter/UserPicker';

const Artist = () => {
  const { artistId } = useParams();
  if (!artistId) {
    return <div>Loading</div>;
  }
  const [dateRange, setDateRange] = useState(999);
  const displayUser = useAppSelector(selectDisplayUser);

  const { data: artistDetailFull } = useGetArtistDetailsFullQuery({
    id: artistId,
    user: displayUser,
  });
  const { data: artistDetailSummary } = useGetArtistDetailsShortQuery({
    id: artistId,
    user: displayUser,
  });

  const sortedAlbums = [...(artistDetailFull?.albums || [])];

  return (
    <div>
      <div className='sm:flex justify-between'>
        <h1 className='text-5xl'>{artistDetailSummary?.name}</h1>

        <div className='flex mt-3 sm:mt-0'>
          <div className='mr-2 '>
            <UserPicker />
          </div>
          <div>
            <DateRangePicker
              disabled={true}
              value={dateRange}
              onChange={(event) => setDateRange(event)}
            />
          </div>
        </div>
      </div>

      <div className='flex mt-8 mb-8'>
        {!artistDetailSummary?.imageUrl && (
          <div className='h-72 w-72'>
            <Skeleton height='100%' />
          </div>
        )}
        {artistDetailSummary?.imageUrl && (
          <img
            className='h-72 rounded-md'
            src={artistDetailSummary?.imageUrl}
            alt={`Artist photo for ${artistDetailSummary?.name}`}
          />
        )}
        <div className='ml-4 mr-4'>
          <Card className='w-48'>
            <div className='text-2xl'>
              {artistDetailFull?.totalPlays || <Skeleton />}
            </div>
            <div>Plays</div>
          </Card>
          <Card className='w-48 mt-4'>
            <div className='text-2xl'>
              {artistDetailFull?.firstListen.split('T')[0] || <Skeleton />}
            </div>
            <div>First Listen</div>
          </Card>
        </div>
        <Card className='flex-1'>
          <div className='text-3xl mb-4'>Top songs</div>
          <ul>
            {artistDetailFull?.topSongs.slice(0, 5).map((track) => {
              return (
                <TopSongRow
                  disablePicture={true}
                  disableArtist={true}
                  key={track.id}
                  trackId={track.id}
                  count={track.count}
                />
              );
            })}
          </ul>
        </Card>
      </div>

      <div className='flex flex-wrap'>
        {sortedAlbums
          .sort((a, b) => b.count - a.count)
          .map((album) => {
            return (
              <Link
                className='hover:bg-spotify rounded-md'
                key={album.id}
                to={`/album/${album.id}`}
              >
                <AlbumCover albumId={album.id} count={album.count} />
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default Artist;
