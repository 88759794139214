import React from 'react';

import FriendsListRow from './FriendsListRow';

interface FriendsListProp {
  friends: string[];

  onDelete?: (friendId: string) => void;
}

const FriendsList = (prop: FriendsListProp) => {
  return (
    <ul>
      {prop.friends.map((friend) => {
        return (
          <FriendsListRow
            key={friend}
            friendId={friend}
            onDelete={prop.onDelete}
          />
        );
      })}
    </ul>
  );
};

export default FriendsList;
