import React from 'react';

import TopSongRow from './TopSongRow';
import { TrackCount } from '../../features/plays/playAPI';

interface TopSongsProps {
  tracks: TrackCount[];
}

const TopSongs = ({ tracks }: TopSongsProps) => {
  return (
    <div>
      <div className='text-3xl mb-4'>Top 10 Songs</div>
      <ul>
        {tracks.map((track) => {
          return (
            <TopSongRow
              key={track.trackId}
              trackId={track.trackId}
              count={track.count}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default TopSongs;
